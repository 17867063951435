import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    InputGroup as ChakraInputGroup,
    IconButton,
    Input,
    InputLeftElement,
    InputRightElement
} from "@chakra-ui/react";

interface PropTypes {
    rightIcon?: IconProp;
    leftIcon?: IconProp;
    isDisabled?: boolean;
    onClick?: () => void;
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
    value?: string | number | readonly string[] | undefined;
    type: string;
    name: string;
    ariaLabel?: string;
    isEnableAutoFocus?: boolean;
}

const InputGroup = ({
    rightIcon,
    leftIcon,
    isDisabled = false,
    onClick,
    onChange,
    onKeyDown,
    value,
    type = "text",
    name,
    ariaLabel = name,
    isEnableAutoFocus = false
}: PropTypes) => {

    return (
        <ChakraInputGroup>
            {leftIcon && (
                <InputLeftElement>
                    <IconButton
                        variant="ghost"
                        colorScheme="white"
                        aria-label={ariaLabel}
                        icon={
                            <FontAwesomeIcon
                                icon={leftIcon}
                                color={
                                    isDisabled
                                        ? "var(--euka-colors-gray-300)"
                                        : "var(--euka-colors-gray-900)"
                                }
                                fontSize={14}
                            />
                        }
                        onClick={onClick}
                        data-testid="leftIconBtn"
                    />
                </InputLeftElement>
            )}
            <Input
                type={type}
                name={name}
                value={value}
                onKeyDown={evt => onKeyDown(evt)}
                onChange={evt => onChange(evt)}
                disabled={isDisabled}
                autoComplete="off"
                data-testid={name}
                autoFocus={isEnableAutoFocus}
            />
            {rightIcon && (
                <InputRightElement>
                    <IconButton
                        variant="ghost"
                        colorScheme="white"
                        aria-label={ariaLabel}
                        icon={
                            <FontAwesomeIcon
                                icon={rightIcon}
                                color={
                                    isDisabled
                                        ? "var(--euka-colors-gray-300)"
                                        : "var(--euka-colors-gray-600)"
                                }
                                fontSize={14}
                            />
                        }
                        onClick={isDisabled ? undefined : onClick}
                        cursor={isDisabled ? "not-allowed" : "pointer"}
                        data-testid="rightIconBtn"
                    />
                </InputRightElement>
            )}
        </ChakraInputGroup>
    );
};

export default InputGroup;
